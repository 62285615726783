import React from 'react';
function AboutStatus() {
    return (
        <section className="  treeseps">
            <div className="container mb-5">
                <div className="row z-1 mt-2">
                    <div className="col-lg-3 maincadrewazno" data-aos="fade-up" data-aos-delay="100">
                        <div className={"centrino"}>
                            <div className=" pricing pb-4 ">
                                <div className="card-body p-2">
                                    <h3 className="pt-3 centrino boldmin" >18+</h3>
                                    <div className=" pricing-list">
                                        Years Of Experience
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 maincadrewazno" data-aos="fade-up" data-aos-delay="100">
                        <div className={"centrino"}>
                            <div className=" pricing pb-4 ">
                                <div className="card-body p-2">
                                    <h3 className="pt-3 centrino boldmin" >240+</h3>
                                    <div className=" pricing-list">
                                        Project complete
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 maincadrewazno" data-aos="fade-up" data-aos-delay="100">
                        <div className={"centrino"}>
                            <div className=" pricing pb-4 ">
                                <div className="card-body p-2">
                                    <h3 className="pt-3 centrino boldmin" >380+</h3>
                                    <div className=" pricing-list">
                                        Happy clients
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 maincadrewazno" data-aos="fade-up" data-aos-delay="100">
                        <div className={"centrino"}>
                            <div className=" pricing pb-4 ">
                                <div className="card-body p-2">
                                    <h3 className="pt-3 centrino boldmin" >30+</h3>
                                    <div className=" pricing-list">
                                        Award wining
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>



            </div>


        </section>
    );
}

export default AboutStatus;
