import React from 'react';

function Trusted() {
    return (
        <section className="bg-light py-4 trusted mt-0">
            <div className="container-fluid pr-0 pl-0">
                <div className="text-center mb-5">
                    <h2 className={"font-weight-bold"}>Trusted By</h2>
                </div>
                <div className="row text-center">
                    <div className="col-lg-12 mb-5 mb-lg-0">

                        <div className="example1 mb-3 ">
                            <h3>
                                Al Badia Residence -
                                 The Exchange Tower -
                                  Fortunato -
                                  Grand Views -
                                Al Badia Hillside -
                                Hyati avenue -
                                Hercules Tower -
                                Living Legends Villa Community -
                                MAG 5 -
                                Murano Residence -
                                No. 9 -
                                Mazaya Towers -
                                Tala Towers -
                                Shams -
                                Farah Towers -
                                Ghanima -
                                Tala 2 -
                                Al Yarmouk -
                                Ritz Residence -
                                Solitaire Cascade -
                                Sparkle Tower -
                                The Pulse Residence -
                                The Pulse Townhouse -
                                The 8 -
                                Al Badia Residence -
                                The Exchange Tower -
                                Fortunato -
                                Grand Views -
                                Al Badia Hillside -
                                Hyati avenue -
                                Hercules Tower -
                                Living Legends Villa Community -
                                MAG 5 -
                                Murano Residence -
                                No. 9 -
                                Mazaya Towers -
                                Tala Towers -
                                Shams -
                                Farah Towers -
                                Ghanima -
                                Tala 2 -
                                Al Yarmouk -
                                Ritz Residence -
                                Solitaire Cascade -
                                Sparkle Tower -
                                The Pulse Residence -
                                The Pulse Townhouse -
                                The 8 -
                                Al Badia Residence -
                                The Exchange Tower -
                                Fortunato -
                                Grand Views -
                                Al Badia Hillside -
                                Hyati avenue -
                                Hercules Tower -
                                Living Legends Villa Community -
                                MAG 5 -
                                Murano Residence -
                                No. 9 -
                                Mazaya Towers -
                                Tala Towers -
                                Shams -
                                Farah Towers -
                                Ghanima -
                                Tala 2 -
                                Al Yarmouk -
                                Ritz Residence -
                                Solitaire Cascade -
                                Sparkle Tower -
                                The Pulse Residence -
                                The Pulse Townhouse -
                                The 8 -
                                Al Badia Residence -
                                The Exchange Tower -
                                Fortunato -
                                Grand Views -
                                Al Badia Hillside -
                                Hyati avenue -
                                Hercules Tower -
                                Living Legends Villa Community -
                                MAG 5 -
                                Murano Residence -
                                No. 9 -
                                Mazaya Towers -
                                Tala Towers -
                                Shams -
                                Farah Towers -
                                Ghanima -
                                Tala 2 -
                                Al Yarmouk -
                                Ritz Residence -
                                Solitaire Cascade -
                                Sparkle Tower -
                                The Pulse Residence -
                                The Pulse Townhouse -
                                The 8 -
                                Al Badia Residence -
                                The Exchange Tower -
                                Fortunato -
                                Grand Views -
                                Al Badia Hillside -
                                Hyati avenue -
                                Hercules Tower -
                                Living Legends Villa Community -
                                MAG 5 -
                                Murano Residence -
                                No. 9 -
                                Mazaya Towers -
                                Tala Towers -
                                Shams -
                                Farah Towers -
                                Ghanima -
                                Tala 2 -
                                Al Yarmouk -
                                Ritz Residence -
                                Solitaire Cascade -
                                Sparkle Tower -
                                The Pulse Residence -
                                The Pulse Townhouse -
                                The 8
                            </h3>

                        </div>

                    </div>

                </div>
            </div>
        </section>
    );
}

export default Trusted;
