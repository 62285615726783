import React, {useState} from 'react';
import logofooter from "../../assets/images/logomini.png";
import link from "../../assets/images/link.png";
import tiktok from "../../assets/images/tiktok.png";
import insta from "../../assets/images/insta.png";
import emailx from "../../assets/images/email.png";
import location from "../../assets/images/location.png";
import phone from "../../assets/images/phone.png";
import RequestEngine from "../../core/RequestEngine";
import {Utilites} from "../../core/Utilites";


function Footer() {
    const [btnloading, setBtnloading] = useState(false)
    const [email, setEmail] = useState("")

    const handleValidSubmit=async ()=> {
        setBtnloading(true)
        let engine = new RequestEngine();

        let data = {
            email:email,
        }

        let res =  await engine.postItem("newsletter",data);


        if(res && res.status ===200){
            Utilites.showSucessMessage("Thank you , Form Submitted!");
        }else{
            Utilites.showErrorMessage("Error , Please Try again later")
        }
        setBtnloading(false);
    }
    return (
        <div id="layoutDefault_footer">
            <footer className="footer pt-3 pb-3 mt-auto footermin footer-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="footer-brand mt-4 pt-3">
                                <img className="img-fluid" src={logofooter} width={100}/>
                                <div className="" >
                                    <p className="footertxt"></p>
                                </div>

                                <div className={"row"} style={{width:300}}>

                                    <div className="col-4">
                                        <a href={"#"} className={"socialbg"} > <img className="img-fluid" src={insta} width={40}/></a>
                                    </div>
                                    <div className="col-4">
                                        <a href={"#"} className={"socialbg"} > <img className="img-fluid" src={tiktok} width={40}/></a>
                                    </div>
                                    <div className="col-4">
                                        <a href={"#"} className={"socialbg"} >  <img className="img-fluid" src={link} width={40}/></a>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                    <hr className="my-3"/>
                    <div className="row align-items-center subfooter">
                        <div className="col-md-10 small ">
                            <a href="javascript:void(0);"  ><img className="" src={phone} width={40} style={{paddingRight:20}}/> 00961 6578 543 </a>
                            <a href="javascript:void(0);"><img className="" src={location} width={40} style={{paddingRight:20}}/> Riyadh, Al Murij District, Olaya Street, 9330</a>
                            <a href="javascript:void(0);"><img className="" src={emailx} width={40} style={{paddingRight:20}}/> info@abjad-sa.com</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
