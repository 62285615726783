import React from 'react';
import expect from "../../../assets/images/expect.png";

function What() {
    return (
        <section className="bg-light py-10">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-9 col-lg-6 order-1 order-lg-0" data-aos="fade-right">
                        <div className="content-skewed content-skewed-right"><img
                            className=" img-fluid  rounded-lg"
                            src={expect} /></div>
                    </div>
                    <div className="col-lg-6 order-0 order-lg-1 mb-5 mb-lg-0" data-aos="fade-left">
                        <div className="mb-5">
                            <span className="badgex">Benefits</span>
                            <h1>What to Expect</h1>
                            <p className="lead">Our array of modules, Socienta, Socienta R and Socienta+ go beyond the traditional platforms in order to exceed your business needs. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default What;
