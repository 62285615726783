import React, {useState} from 'react';

import item1 from "../../assets/images/portfolio/item1.png";
import item2 from "../../assets/images/portfolio/item2.png";
import item3 from "../../assets/images/portfolio/item3.png";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
function PortfolioList() {
    const [index, setIndex] = useState(0)
    return (
        <section className="  treeseps">
            <div className="container mb-5">


                <div className="row z-1">
                    <div className={"filterwrapper"}>

                        <div className={"filteractive"}>All</div>
                        <div className={"filter"}>Branding</div>
                        <div className={"filter"}>Ui/Ux</div>
                        <div className={"filter"}>D</div>
                    </div>
                </div>

                <div className="row z-1">

                    <div className="col-12 maincadrewazno" data-aos="fade-up" data-aos-delay="100">
                        <ResponsiveMasonry
                            columnsCountBreakPoints={{350: 1, 750: 1, 900: 2}}
                        >
                            <Masonry>
                                <div className={"massmin"}>
                                    <img src={item1} className="pt-3 pl-3" width={500}   />
                                    <div className={"tagblog"}>
                                        <h6 className={"tag"}>Branding</h6>
                                        <h6 className={"tag"}>Website Design</h6>
                                        <h6 className={"tag"}>Website Development</h6>
                                    </div>
                                    <div className={"clear"}></div>
                                    <h3 className={"bold taghead"}>KILT</h3>
                                    <div className={"tagbody"}>
                                        We took on the challenge of creating the new website for tangerine, a global design pioneer.
                                    </div>
                                </div>

                                <div className={"massmin"}>
                                    <img src={item2} className="pt-3 pl-3" width={400}   />
                                    <div className={"tagblog"}>
                                        <h6 className={"tag"}>Branding</h6>
                                        <h6 className={"tag"}>Digital Marketing</h6>
                                    </div>
                                    <div className={"clear"}></div>
                                    <h3 className={"bold taghead"}>OLOO</h3>
                                    <div className={"tagbody"}>
                                        We took on the challenge of creating the new website for tangerine, a global design pioneer.
                                    </div>
                                </div>

                                <div className={"massmin"}>
                                    <img src={item3} className="pt-3 pl-3" width={200}   />
                                    <div className={"tagblog"}>
                                        <h6 className={"tag"}>Branding</h6>
                                        <h6 className={"tag"}>Ui/Ux Desgin</h6>
                                    </div>
                                    <div className={"clear"}></div>
                                    <h3 className={"bold taghead"}>PUNKT</h3>
                                    <div className={"tagbody"}>
                                        We took on the challenge of creating the new website for tangerine, a global design pioneer.
                                    </div>
                                </div>

                            </Masonry>
                        </ResponsiveMasonry>
                    </div>


                </div>


            </div>


        </section>
    );
}

export default PortfolioList;
