import React from 'react';

import final from "../../../assets/images/final.png";
function Problems() {
    return (
        <section className="py-10">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-9 col-lg-6 " data-aos="fade-left">
                        <div className="mb-5">
                            <h2>Our Customize Offers  </h2>
                            <p className="lead">
                                We get it - you want the biggest bang for your buck. That's why our customized offers are designed to fit your unique needs, budget, and goals, so you can get the most out of our services and see the results you crave
                            </p>

                            <div className={"sideblock"}>
                                <div className={"edge"}/>
                                <h3>Digital solution  </h3>
                                <p className="lead">
                                    We offer top-notch SEO and SMM to give you increased online visibility, higher website traffic and conversions, and improved customer engagement. Plus, you'll see better ROI on your digital marketing campaigns
                                </p>
                            </div>
                            <div className={"sideblock"}>
                                <div className={"edge "} style={{background:"#5CB098"}}/>
                                <h3>Branding  </h3>
                                <p className="lead">
                                    Our branding services help tell your business's unique story in a captivating and approachable way
                                </p>
                            </div>
                            <div className={"sideblock"}>
                                <div className={"edge "} style={{background:"#3E6190"}}/>
                                <h3>Content  </h3>
                                <p className="lead">
                                    Our content services go beyond just writing - we craft compelling narratives that engage your audience and deliver your message in a way that's both informative and entertaining
                                </p>
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-6  mb-5 mb-lg-0" data-aos="fade-right">
                        <div className="content-skewed content-skewed-right"><img
                            className=" img-fluid  rounded-lg"
                            src={final} /></div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default Problems;
