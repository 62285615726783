import React from 'react';

import Navigation from "../common/Navigation";
import Footer from "../common/Footer";
import AboutHeader from "./AboutHeader";
import AboutListing from "./AboutListing";
import AboutStatus from "./AboutStatus";
import whyus from "../../assets/images/whyus.png";
function About() {
    return (
        <div id="layoutDefault">
            <div id="layoutDefault_content">
                <main>
                    <Navigation selected={1} subselected={-1} />
                    <AboutHeader/>
                    <div className="container pb-5">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-12  " data-aos="fade-left">
                                <div className="mb-5 text-center">
                                    <img src={whyus} className="pt-3 pl-3" width={200}  />
                                    <p className={"pt-4"}>
                                        At Abjad, we totally get that running a business can be tough, especially when you're competing in today's hyper-competitive market. But don't sweat it, because we're here to help! Abjad creates high-impact campaigns with killer content and the latest tech, personalized to your needs and goals. With our deep understanding of the Saudi world, we craft engaging campaigns that resonate with your audience and drive real results.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <AboutListing/>




                    <AboutStatus/>
                </main>
            </div>
            <Footer/>
        </div>
    );
}

export default About;
