import React from 'react';

import target from "../../../assets/images/target.png";

function OurVision() {
    return (
        <section className="methodologie py-3">
            <div className="container ">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-3  mb-5 mb-lg-0" data-aos="fade-right">
                        <div className="mb-5">
                            <h1 className="mt-2">Our</h1>
                            <h1 className="mt-2 orange">Vision</h1>
                        </div>
                    </div>
                    <div className="col-lg-9 " data-aos="fade-left">
                        <div className="content-skewed content-skewed-right bluecadre">
                            Abjad's vision is to be the premier marketing agency in Saudi Arabia, crafting innovative campaigns that highlight each client's unique story and resonate with their target audience.
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default OurVision;
