import React from 'react';

import Navigation from "../common/Navigation";
import Footer from "../common/Footer";
import PortfolioList from "./PortfolioList";
import PortfolioHeader from "./PortfolioHeader";
import clients from "../../assets/images/clients.png";

function Portfolio() {
    return (
        <div id="layoutDefault">
            <div id="layoutDefault_content">
                <main>
                    <Navigation selected={3} subselected={-1} />
                    <PortfolioHeader/>
                    <PortfolioList />


                    <section className="  treeseps">
                        <div className="container mb-5">


                            <div className="row z-1">
                                <div className="text-center mt-5 pt-5 pb-5">
                                    <h1 className="mt-5 greenx">Our Clients</h1>
                                    <div className={"row"}>
                                        <div className="col-12">
                                            <img src={clients} className="pt-3 pl-3 img-fluid"  />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </main>
            </div>
            <Footer/>
        </div>
    );
}

export default Portfolio;
