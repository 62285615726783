import React from 'react';

import target from "../../../assets/images/target.png";
import ButtonLoader from "../../../components/ButtonLoader/ButtonLoader";
import {Link} from "react-router-dom";

function Methodologie() {
    return (
        <section className="methodologie py-10">
            <div className="container">
                <div className="row align-items-center justify-content-center">


                    <div className="col-lg-9  mb-5 mb-lg-0" data-aos="fade-left">
                        <div className="mb-5">
                            <div className={"italic"}>Quick Contact!</div>
                            <h1 className="mt-2"> Let’s Talk For Your</h1>
                            <h1 className="mt-2 orange"> Next Project</h1>
                            <div className="" >
                                <p className="lead">Are you itching to dive into your next project? Let's team up and bring your creative ideas to life with style and flair, leaving your audience in awe.</p>
                            </div>
                        </div>
                    </div>

                    <div className=" col-lg-3 " data-aos="fade-right">
                        <div className="content-skewed content-skewed-right">
                            <div className="text-center">
                                <Link to="/contact"  >
                                <ButtonLoader className="btn-teal btn rounded-pill px-4 ml-lg-4 green" loading={false} >MAKE AN EQUIRY</ButtonLoader>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default Methodologie;
