import React from 'react';

import laptop from "../../assets/images/laptop.png"
import Navigation from "../common/Navigation";
import Header from "./partial/Header";
import ThreeSteps from "./partial/ThreeSteps";
import What from "./partial/What";
import Problems from "./partial/Problems";
import Reduce from "./partial/Reduce";
import Solution from "./partial/Solution";
import CreateBetter from "./partial/CreateBetter";
import Methodologie from "./partial/Methodologie";
import Trusted from "./partial/Trusted";
import SatisfiedUser from "./partial/SatisfiedUser";
import Footer from "../common/Footer";
import Contact from "./partial/Contact";
import OurMission from "./partial/OurMission";
import OurVision from "./partial/OurVision";

function Landing() {
    return (
        <div id="layoutDefault">
            <div id="layoutDefault_content">
                <main>
                    <Navigation selected={0} subselected={-1} />
<Header/>
<ThreeSteps/>

                    <OurMission/>
                    <OurVision/>
<Problems/>

<SatisfiedUser/>

                    <Methodologie/>

                </main>
            </div>
<Footer/>
        </div>
);
}

export default Landing;
