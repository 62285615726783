import React from 'react';

import Navigation from "../common/Navigation";
import Footer from "../common/Footer";
import ServiceHeader from "./ServiceHeader";
import SatisfiedUser from "../home/partial/SatisfiedUser";
import ServicePoints from "./ServicePoints";
import ServiceList from "./ServiceList";

function Services() {
    return (
        <div id="layoutDefault">
            <div id="layoutDefault_content">
                <main>
                    <Navigation selected={2} subselected={-1} />
                    <ServiceHeader/>
                    <ServicePoints/>
                    <ServiceList/>
                    <SatisfiedUser/>
                </main>
            </div>
            <Footer/>
        </div>
    );
}

export default Services;
