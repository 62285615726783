import React from 'react';

import Navigation from "../common/Navigation";
import Footer from "../common/Footer";
import Methodologie from "../home/partial/Methodologie";
import ThreeSteps from "../home/partial/ThreeSteps";
import Header from "../home/partial/Header";
import PricingList from "./PricingList";
import PricingHeader from "./PricingHeader";

function Pricing() {
    return (
        <div id="layoutDefault">
            <div id="layoutDefault_content">
                <main>
                    <Navigation selected={4} subselected={-1} />
                    <PricingHeader/>
                    <PricingList />
                </main>
            </div>
            <Footer/>
        </div>
    );
}

export default Pricing;
