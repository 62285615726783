import React from 'react';

import target from "../../../assets/images/target.png";

function OurMission() {
    return (
        <section className="methodologie py-10">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-9 order-1 order-lg-0" data-aos="fade-right">
                        <div className="content-skewed content-skewed-right greencadre">
                            Abjad's mission is to revolutionize marketing in Saudi Arabia by delivering innovative campaigns that cater to each client's unique needs.
                        </div>
                    </div>
                    <div className="col-lg-3 order-0 order-lg-1 mb-5 mb-lg-0" data-aos="fade-left">
                        <div className="mb-5">
                            <h1 className="mt-2">Our</h1>
                            <h1 className="mt-2 orange">Mission</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OurMission;
