import React from 'react';

import Navigation from "../common/Navigation";
import Footer from "../common/Footer";
import Form from "./Form";
import ContactHeader from "../home/partial/ContactHeader";
import ContactListing from "../home/partial/ContactListing";

function ContactUs() {
    return (
        <div id="layoutDefault">
            <div id="layoutDefault_content">
                <main>
                    <Navigation selected={2} subselected={-1} />
                    <ContactHeader/>
                    <ContactListing/>
                    <Form/>
                </main>
            </div>
            <Footer/>
        </div>
    );
}

export default ContactUs;
