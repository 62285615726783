import React       from 'react';
import PropTypes   from 'prop-types';

import {
    Label,
    FormGroup,
    Row,
    Col
} from "reactstrap";
import { AvField } from 'availity-reactstrap-validation';

const propTypes = {
    spinColor:      PropTypes.string,
    spinConfig:     PropTypes.object,
    spinAlignment:  PropTypes.string
};

function FormRow({
                     label       = 'Label',
                     type       = 'text',
                     name = "" ,
                     viewonly=false,
                     includeRow = true,
                     required = true,
                     hidelabel = false,
                     readonly = false,
                     errorMessage = "Required",
                     helpMessage = "",
                     labelW="2",
                     formW="12",
                     placeholder="",
                     row=false,
                     data       ,
                     changeInput,
                     style={},
                     labelStyle={}

                 }) {


    if(hidelabel){

        if(viewonly){
           return <Col sm={formW} style={{    textAlign: "right",
                    padding: 10}}>
                    <FormGroup >
                        <b>{data}</b>
                    </FormGroup>

                </Col>
        }
       return  <AvField
               name={name}
               type={type} // text  or textarea

               placeholder={placeholder}
               required = {required}
               readOnly = {readonly}
               errorMessage={errorMessage}
               helpMessage={helpMessage}
               style={style}
               autoComplete="none"
               onChange={e =>
                   changeInput && changeInput(e, name)
               }
           />
    }




    let output = <><Label for={name} sm={formW}>{label} {required && <span style={{color:"red"}}>*</span>}</Label>
        <Col sm={formW}>
            <FormGroup style={{}}>
                <AvField
                    name={name}
                    type={type} // text  or textarea
                    placeholder={placeholder}
                    placeholderColor={"red"}
                    required = {required}
                    readOnly = {readonly}
                    errorMessage={errorMessage}
                    helpMessage={helpMessage}
                    autoComplete="none"
                    onChange={e =>
                       changeInput && changeInput(e, name)
                    }
                />

            </FormGroup>

        </Col>

        </>


    if(row){
        output = <>
            <FormGroup >
                <AvField
                    name={name}
                    type={type} // text  or textarea

                    required = {required}
                    readOnly = {readonly}
                    errorMessage={errorMessage}
                    helpMessage={helpMessage}
                    autoComplete="none"
                    //onChange={e =>
                      //>  changeInput && changeInput(e, name)
                    //}
                />
            </FormGroup>
            <Label for={name} style={labelStyle}>{label}</Label>
        </>
    }
    if(includeRow){
        return ( <Row>{output}</Row>);
    }
    return output;

}

FormRow.propTypes = propTypes;

export default FormRow;
