import React from 'react';

import step1 from "../../assets/images/minicadre.png";
function PricingList() {
    return (
        <section className="  treeseps">
            <div className="container mb-5">



                <div className="row z-1">

                    <div className="col-lg-6 maincadrewazno mb-2" data-aos="fade-up" data-aos-delay="100">
                        <div className={"cadrewazno"}>
                            <div className=" pricing pb-4 ">
                                <div className="card-body p-2">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src={step1} className="pt-3 pl-3" width={50}  />
                                                <h3 className="pt-3 pl-3" >Service with perspective</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fa-ul pricing-list">
                                        Our services come packed with a killer combo of creativity and strategy, giving your brand the power to stand out in a sea of sameness. We don't just bring a fresh perspective, we bring a game-changing approach that'll help your brand dominate the competition.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 maincadrewazno mb-2" data-aos="fade-up" data-aos-delay="100">
                        <div className={"cadrewazno"}>
                            <div className=" pricing pb-4 ">
                                <div className="card-body p-2">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src={step1} className="pt-3 pl-3" width={50}  />
                                                <h3 className="pt-3 pl-3" >Access to new paths</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" pricing-list">
                                        With our services, you'll have the keys to unlock new doors, explore uncharted territories, and navigate exciting paths towards success.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>






                </div>

                <div className="row z-1 mt-5">



                    <div className="col-lg-6 maincadrewazno mb-2" data-aos="fade-up" data-aos-delay="100">
                        <div className={"cadrewazno"}>
                            <div className=" pricing pb-4 ">
                                <div className="card-body p-2">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src={step1} className="pt-3 pl-3" width={50}  />
                                                <h3 className="pt-3 pl-3" >Integration with impact</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" pricing-list">
                                        At Abjad, we take integration seriously. Our goal is to seamlessly blend your marketing efforts across channels to make a lasting impact on your audience. Let us show you how we can take your brand to the next level.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 maincadrewazno mb-2" data-aos="fade-up" data-aos-delay="100">
                        <div className={"cadrewazno"}>
                            <div className=" pricing pb-4 ">
                                <div className="card-body p-2">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src={step1} className="pt-3 pl-3" width={50}  />
                                                <h3 className="pt-3 pl-3" >Distance vision</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" pricing-list">
                                        Looking beyond the horizon of your current goals is what we're all about - our distance vision service helps you envision your brand's future and make it a reality, with customized strategies and expert guidance to ensure your success.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>


            </div>


        </section>
    );
}

export default PricingList;
