import React,{useEffect,useState} from 'react'
import { AvForm } from "availity-reactstrap-validation";
import FormRow from "../../components/Row/FormRow";
import RequestEngine from "../../core/RequestEngine";
import {Utilites} from "../../core/Utilites";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";


function Form() {

    const [btnloading, setBtnloading] = useState(false)
    const [fname, setFname] = useState("")
    const [lname, setLname] = useState("")
    const [email, setEmail] = useState("")
    const [location, setLocation] = useState("")
    const [regarding, setRegarding] = useState("")
    const [more, setMore] = useState("")
    const [interested, setInterested] = useState("")
    const handleValidSubmit=async ()=> {
        Utilites.showSucessMessage("Thank you , Form Submitted!");
        return;
        setBtnloading(true)
        let engine = new RequestEngine();

        let data = {
            first:fname,
            last:lname,
            location:location,
            email:email,
            more:more
        }

        let res =  await engine.postItem("contact",data);


        if(res && res.status ===200){

            Utilites.showSucessMessage("Thank you , Form Submitted!");
        }else{
            Utilites.showErrorMessage("Error , Please Try again later")
        }
        setBtnloading(false);
    }
    return (
        <section className="fullcontactform mt-5 ">
            <div className="container">
                <div className="row align-items-center justify-content-center ">

                    <div className="col-lg-12  mb-5 mb-lg-0" data-aos="fade-left">
                        <div className="mb-5 card p-5 bg-light">
                            <div className="m-3" >
                                <AvForm onValidSubmit={() => handleValidSubmit()} className="contactinput noshadow">


                                    <div className="row">
                                        <div className="col-12 col-lg-6" >
                                            <FormRow label="First name"  placeholder={"Name"}  name="fname" data={fname} changeInput={(e)=>{
                                                setFname(e.target.value)
                                            }} />
                                        </div>
                                        <div className="col-12 col-lg-6" >
                                            <FormRow label="Last name" placeholder={"Company"}  name="lname" data={lname} changeInput={(e)=>{
                                                setLname(e.target.value)
                                            }} />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-lg-6" >
                                            <FormRow label="Email Address"  placeholder={"Your Email"} type={"email"} name="name" data={email} changeInput={(e)=>{
                                                setEmail(e.target.value)
                                            }} />
                                        </div>
                                        <div className="col-12 col-lg-6" >
                                            <FormRow label="Phone Number"  placeholder={"Your location"}  name="location" data={location} changeInput={(e)=>{
                                                setLocation(e.target.value)
                                            }} />
                                        </div>
                                    </div>



                                    <FormRow label="Comments / Questions" type={"textarea"} placeholder={"Comments / Questions"}  name="more" data={more} changeInput={(e)=>{
                                        setMore(e.target.value)
                                    }} />

                                    <div className="text-center">
                                        <ButtonLoader loading={btnloading}   className="btn-teal btn rounded px-4 ml-lg-4 btn-lg"
                                           >
                                            Submit
                                        </ButtonLoader>
                                    </div>


                                </AvForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Form;
