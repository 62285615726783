import React from 'react';

import servicesteam from "../../assets/images/servicesteam.jpg";
import topservices from "../../assets/images/topservices.png";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
function ServicePoints() {
    return (
        <section className="py-10">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6  mb-5 mb-lg-0" data-aos="fade-right">
                        <div className="content-skewed content-skewed-right"><img
                            className=" img-fluid  rounded-lg"
                            src={servicesteam} /></div>

                    </div>
                    <div className="col-md-9 col-lg-6 " data-aos="fade-left">
                        <div className="mb-5">
                            <img className=" img-fluid  " src={topservices} />
                            <p>
                                Select your project from here by clicking on projects. it helps us what you need from us. Make your better version of your web, app or video.
                            </p>
                            <div className={"servicepoints"}>
                                <p className="lead">
                                  <span style={{background:"#5CB098"}}>1</span>  Personalized Attention: We don't do cookie-cutter solutions - we'll get to know your business and craft strategies tailored just for you
                                </p>
                            </div>
                            <div className={"servicepoints"}>
                                <p className="lead">
                                    <span style={{background:"#F0861E"}}> 2</span> Proven Expertise: Our team has been around the block and knows how to bring the heat. We've got the experience and know-how to help your business shine.
                                </p>
                            </div>
                            <div className={"servicepoints"}>
                                <p className="lead">
                                    <span style={{background:"#DB6E44"}}>3</span> Clear Communication: We won't leave you in the dark - we'll keep you in the loop every step of the way, so you can kick back and watch the magic happen.
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className="text-center pt-5">
                <ButtonLoader className="btn-teal btn rounded-pill px-4  green" loading={false} >MAKE AN EQUIRY</ButtonLoader>
            </div>
        </section>
    );
}

export default ServicePoints;
