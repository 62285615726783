import React from 'react';

import servicesteam from "../../assets/images/servicesteam.jpg";
import service1 from "../../assets/images/service1.png";
import service2 from "../../assets/images/service2.png";
import service3 from "../../assets/images/service3.png";
import service4 from "../../assets/images/service4.png";
import service5 from "../../assets/images/service5.png";
import service6 from "../../assets/images/service6.png";


import service1app from "../../assets/images/service1app.png";
import service2app from "../../assets/images/service2app.png";
import service3app from "../../assets/images/service3app.png";
import service4app from "../../assets/images/service4app.png";
import service5app from "../../assets/images/service5app.png";
import service6app from "../../assets/images/service6app.png";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import {Link} from "react-router-dom";
function ServiceList() {
    return (
        <section className="py-10">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6  mb-5 mb-lg-0" data-aos="fade-right">
                        <div className="content-skewed content-skewed-right"><img
                            className=" img-fluid  rounded-lg"
                            src={service1app} /></div>
                    </div>
                    <div className="col-md-9 col-lg-6 " data-aos="fade-left">
                        <div className="mb-5">
                            <img className="img-fluid  "  width={200} src={service1} />
                            <p>
                                If you're in need of compelling visual elements and innovative design to make your brand stand out, our design services can help you achieve a distinctive and engaging aesthetic that is truly one-of-a-kind.
                            </p>
                            <div className=" pt-5">
                                <Link to="/contact"  >
                                <ButtonLoader className="btn-teal btn rounded-pill px-4  orangebtn" loading={false} >Learn More</ButtonLoader>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row align-items-center justify-content-center">


                    <div className="col-md-9 col-lg-6 " data-aos="fade-left">
                        <div className="mb-5">
                            <img className="img-fluid  "  width={200} src={service2} />
                            <p>
                                Are you searching for a way to enhance your brand and differentiate yourself from competitors? Look no further than our creative services, providing a distinct and enjoyable approach to realizing your vision!
                            </p>
                            <div className=" pt-5">
                                <Link to="/contact"  >
                                    <ButtonLoader className="btn-teal btn rounded-pill px-4  orangebtn" loading={false} >Learn More</ButtonLoader>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6  mb-5 " data-aos="fade-right">
                        <div className="content-skewed content-skewed-right"><img
                            className=" img-fluid  rounded-lg"
                            src={service2app} /></div>
                    </div>


                </div>
            </div>

            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6  mb-5 " data-aos="fade-right">
                        <div className="content-skewed content-skewed-right"><img
                            className=" img-fluid  rounded-lg"
                            src={service3app} /></div>
                    </div>
                    <div className="col-md-9 col-lg-6 " data-aos="fade-left">
                        <div className="mb-5">
                            <img className="img-fluid  "  width={200} src={service3} />
                            <p>
                                While digital marketing dominates the landscape, we believe that our non-digital marketing services can be just as impactful in promoting your business in Saudi Arabia, providing a distinctive and amiable approach to spreading the word about your brand.
                            </p>
                            <div className=" pt-5">
                                <Link to="/contact"  >
                                    <ButtonLoader className="btn-teal btn rounded-pill px-4  orangebtn" loading={false} >Learn More</ButtonLoader>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-9 col-lg-6 " data-aos="fade-left">
                        <div className="mb-5">
                            <img className="img-fluid  "  width={200} src={service4} />
                            <p>
                                Looking to take your business to new heights in Saudi Arabia? Our digital marketing services offer a customized strategy that's engaging, innovative, and tailored to your brand's unique personality.
                            </p>
                            <div className=" pt-5">
                                <Link to="/contact"  >
                                    <ButtonLoader className="btn-teal btn rounded-pill px-4  orangebtn" loading={false} >Learn More</ButtonLoader>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6  mb-5 " data-aos="fade-right">
                        <div className="content-skewed content-skewed-right"><img
                            className=" img-fluid  rounded-lg"
                            src={service4app} /></div>
                    </div>

                </div>
            </div>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6  mb-5 " data-aos="fade-right">
                        <div className="content-skewed content-skewed-right"><img
                            className=" img-fluid  rounded-lg"
                            src={service5app} /></div>
                    </div>
                    <div className="col-md-9 col-lg-6 " data-aos="fade-left">
                        <div className="mb-5">
                            <img className="img-fluid  "  width={200} src={service5} />
                            <p>
                                Your logo is your brand's wingman. Let us create a bold, unforgettable logo that captures your spirit and stands out from the crowd.
                            </p>
                            <div className=" pt-5">
                                <Link to="/contact"  >
                                    <ButtonLoader className="btn-teal btn rounded-pill px-4  orangebtn" loading={false} >Learn More</ButtonLoader>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-9 col-lg-6 " data-aos="fade-left">
                        <div className="mb-5">
                            <img className="img-fluid  " width={200} src={service6} />
                            <p>
                                Get ready to take your online presence to the next level with our web design and app development services - we'll work with you to create a modern and engaging digital experience that's both fun and functional!
                            </p>
                            <div className=" pt-5">
                                <Link to="/contact"  >
                                    <ButtonLoader className="btn-teal btn rounded-pill px-4  orangebtn" loading={false} >Learn More</ButtonLoader>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6  mb-5 mb-lg-0" data-aos="fade-right">
                        <div className="content-skewed content-skewed-right"><img
                            className=" img-fluid  rounded-lg"
                            src={service6app} /></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceList;
