import React,{useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Link, withRouter
} from "react-router-dom";
import logo from "../../assets/images/logo.png";
import wlogo from "../../assets/images/logowhite.png";



function Navigation(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.location]);
    return (
        <nav className="navbar navbar-marketing navbar-expand-lg bg-transparent navbar-dark fixed-top">
            <div className="container">
                <Link to="/" className="navbar-brand text-white" >
                    <img src={logo}  className={"blacklogo"} />
                    <img src={wlogo}  className={"whitelogo"} />
                </Link>
                <button style={{position:"relative",left:-10}} className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">

                    <FontAwesomeIcon icon="bars" color={"white"} />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto ml-lg-5">
                        <li className="nav-item"><Link to="/" className={"nav-link "+ (props.selected===0&& "xselected")}>Home</Link></li>

                        <li className="nav-item">
                            <Link to="/about" className={"nav-link "+ (props.selected===1&& "xselected")} >About us</Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/services" className={"nav-link "+ (props.selected===2&& "xselected")} >Services</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/portfolio" className={"nav-link "+ (props.selected===3&& "xselected")} >Portfolio</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/pricing" className={"nav-link "+ (props.selected===4&& "xselected")} >Pricing</Link>
                        </li>

                    </ul>

                </div>
                <Link to="/contact" className="hideonmobile btn-teal btn rounded-pill px-4 ml-lg-4 orangebtn"
                   >
                    Contact Us
                </Link>
            </div>
        </nav>

    );
}

export default Navigation;
