import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import star from "../../../assets/images/star.webp";


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 400 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 400 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1424, min: 664 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 764, min: 390 },
        items: 1
    }
};
function SatisfiedUser() {
    return (
        <section className=" py-3 staistfied ">
            <div className="container">
                <div className="text-center  pb-5">
                    <h1 className="mt-5 text-black">What Our Clients Says</h1>
                    <p className="lead text-black">
                        Our clients trust us to bring their vision to life, from ideation to execution.
                    </p>
                </div>
                    <Carousel responsive={responsive}>

                        <div >
                            <div className="card m-2 reviewfixedh  ">
                                <div className="card-body p-2">

                                    <div className="row pt-3 pl-3">
                                        <div className="col-9 pl-0 pt-2 reviewsource">
                                            <h5 className="pl-3 pt-3 font-weight-bold reviewername " >Nonum al</h5>
                                            <p className="pl-3 reviewsub">Saudi Arabia</p>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <div
                                            className="quotex">
                                            <img src={star} className="pb-3"  style={{ width: 130}} />
                                        </div>
                                        <div className=" review ">
                                            I highly recommend Abjad to any business looking to elevate their branding and take their marketing efforts to the next level. Trust me, you won't be disappointed!
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div >
                            <div className="card m-2 reviewfixedh  ">
                                <div className="card-body p-2">

                                    <div className="row pt-3 pl-3">
                                        <div className="col-9 pl-0 pt-2 reviewsource">
                                            <h5 className="pl-3 pt-3 font-weight-bold reviewername " >Nonum al</h5>
                                            <p className="pl-3 reviewsub">Saudi Arabia</p>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <div
                                            className="quotex">
                                            <img src={star} className="pb-3"  style={{ width: 130}} />
                                        </div>
                                        <div className=" review ">
                                            From developing engaging content to crafting effective ad campaigns, the team at Abjad knows exactly how to capture your audience's attention and drive results. They're always on top of the latest trends and algorithms, which means they're able to create social media strategies that are both cutting-edge and effective.
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div >
                            <div className="card m-2 reviewfixedh  ">
                                <div className="card-body p-2">

                                    <div className="row pt-3 pl-3">
                                        <div className="col-9 pl-0 pt-2 reviewsource">
                                            <h5 className="pl-3 pt-3 font-weight-bold reviewername " >Nonum al</h5>
                                            <p className="pl-3 reviewsub">Saudi Arabia</p>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <div
                                            className="quotex">
                                            <img src={star} className="pb-3"  style={{ width: 130}} />
                                        </div>
                                        <div className=" review ">
                                            Working with Abjad was an absolute pleasure. They were always professional, responsive, and truly cared about delivering a product that met my needs. I couldn't be happier with the finished app and have received rave reviews from users.
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div >
                            <div className="card m-2 reviewfixedh  ">
                                <div className="card-body p-2">

                                    <div className="row pt-3 pl-3">
                                        <div className="col-9 pl-0 pt-2 reviewsource">
                                            <h5 className="pl-3 pt-3 font-weight-bold reviewername " >Nonum al</h5>
                                            <p className="pl-3 reviewsub">Saudi Arabia</p>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <div
                                            className="quotex">
                                            <img src={star} className="pb-3"  style={{ width: 130}} />
                                        </div>
                                        <div className=" review ">
                                            “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad”
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </Carousel>







            </div>
        </section>
    );
}

export default SatisfiedUser;
