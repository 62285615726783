import React from 'react';

import step1 from "../../../assets/images/minicadre.png";
function ContactListing() {
    return (
        <section className="  treeseps">
            <div className="container mb-5">


                <div className="row z-1">

                    <div className="col-lg-4 maincadrewazno" data-aos="fade-up" data-aos-delay="100">
                        <div className={"cadrewazno"} style={{height:200}}>
                            <div className=" pricing pb-4 ">
                                <div className="card-body p-2">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src={step1} className="pt-3 pl-3" width={50}  />
                                                <h3 className="pt-3 pl-3" >Abjad Office No. </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" pricing-list">
                                        + 966 11 291 0149
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 maincadrewazno" data-aos="fade-up" data-aos-delay="100">
                        <div className={"cadrewazno"} style={{height:200}}>
                            <div className=" pricing pb-4 ">
                                <div className="card-body p-2">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src={step1} className="pt-3 pl-3" width={50}  />
                                                <h3 className="pt-3 pl-3" >Email</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fa-ul pricing-list">
                                        info@abjad-sa.com
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 maincadrewazno" data-aos="fade-up" data-aos-delay="100">
                        <div className={"cadrewazno"} style={{height:200}}>
                            <div className=" pricing pb-4 ">
                                <div className="card-body p-2">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src={step1} className="pt-3 pl-3" width={50}  />
                                                <h3 className="pt-3 pl-3" >Location</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" pricing-list">
                                        Riyadh, Al Murij District, Olaya Street, 9330
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>


        </section>
    );
}

export default ContactListing;
