import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import './index.css';
import Landing from './pages/home/Landing';

import * as serviceWorker from './serviceWorker';

import './index.scss';
import ContactUs from "./pages/contact/ContactUs";
import Pricing from "./pages/abjad/Pricing";
import About from "./pages/abjad/About";
import Portfolio from "./pages/abjad/Portfolio";
import Services from "./pages/abjad/Services";

const history = createBrowserHistory();



const App = () => (
    <Router history={history}>
        <ReactNotification />
        <Switch>
            <Route excat path="/contact">
                <ContactUs />
            </Route>

            <Route path="/pricing">
                <Pricing />
            </Route>
            <Route path="/services">
                <Services />
            </Route>

            <Route path="/portfolio">
                <Portfolio />
            </Route>

            <Route path="/about">
                <About />
            </Route>
            <Route path="/">
                <Landing />
            </Route>
        </Switch>
    </Router>
)

const loader = document.querySelector('.loader');
// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader--hide');

const hideLoader = () => loader.classList.add('loader--hide');
setTimeout(() =>
        // the show/hide functions are passed as props
        ReactDOM.render(
            <App
                hideLoader={hideLoader}
                showLoader={showLoader}
            />,
            document.getElementById('root')
        )
    , 0);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
