import React from 'react';
import laptop from "../../assets/images/xbgabout.png";


function AboutHeader() {
    return (
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary headerx">
            <div className="page-header-content">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12 d-none d-lg-block" data-aos="fade-up" data-aos-delay="50">
                            <img className="img-fluid" src={laptop}/></div>
                    </div>
                </div>
            </div>
            <div className="svg-border-rounded text-white">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34"
                     preserveAspectRatio="none" fill="currentColor">
                    <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"/>
                </svg>
            </div>
        </header>

    );
}

export default AboutHeader;
