import React from 'react';

import step1 from "../../../assets/images/minicadre.png";
function ThreeSteps() {
    return (
        <section className="  treeseps">
            <div className="container">

                <div className="text-center mt-3 pt-2 pb-5">
                    <h1 className="mt-3">Our Services</h1>
                    <p className="lead mt-3"></p>
                </div>

                <div className="row z-1">

                    <div className="col-lg-3 maincadrewazno mb-2" data-aos="fade-up" data-aos-delay="100">
                        <div className={"cadrewazno"}>
                            <div className=" pricing pb-4 ">
                                <div className="card-body p-2">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src={step1} className="pt-3 pl-3" width={50}  />
                                                <h3 className="pt-3 pl-3" >Design</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" pricing-list">
                                        Stand out from the crowd with our bespoke design services, crafted to turn heads and capture hearts. From stunning visuals to captivating branding, our team of design gurus will help you create a visual identity that's as unique as you are
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 maincadrewazno mb-2" data-aos="fade-up" data-aos-delay="100">
                        <div className={"cadrewazno"}>
                            <div className=" pricing pb-4 ">
                                <div className="card-body p-2">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src={step1} className="pt-3 pl-3" width={50}  />
                                                <h3 className="pt-3 pl-3" >Branding</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fa-ul pricing-list">
                                        Let our branding experts help you create a brand that's unforgettable, with a message that resonates and a visual identity that stands the test of time. Trust us to build your brand and take your business to new heights.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 maincadrewazno mb-2" data-aos="fade-up" data-aos-delay="100">
                        <div className={"cadrewazno"}>
                            <div className=" pricing pb-4 ">
                                <div className="card-body p-2">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src={step1} className="pt-3 pl-3" width={50}  />
                                                <h3 className="pt-3 pl-3" >Ui/Ux</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" pricing-list">
                                        Get ready for a digital experience like no other with our killer UI/UX designs. Our team of experts will help you create a sleek, intuitive interface that keeps your users hooked, while delivering a seamless experience across all devices.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 maincadrewazno mb-2" data-aos="fade-up" data-aos-delay="100">
                        <div className={"cadrewazno"}>
                            <div className=" pricing pb-4 ">
                                <div className="card-body p-2">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src={step1} className="pt-3 pl-3" width={50}  />
                                                <h3 className="pt-3 pl-3" >Logo</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" pricing-list">
                                        Your logo is your brand's wingman. Let us create a bold, unforgettable logo that captures your spirit and stands out from the crowd.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>


            </div>


        </section>
    );
}

export default ThreeSteps;
